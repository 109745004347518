import React, { useState, useContext, useEffect } from "react";
import { FaArrowUp, FaArrowDown, FaShare, FaComment } from "react-icons/fa";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import "../css/RedditPost.css";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { useTheme } from "./ThemeContext";
import TagsList from "./TagsList";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import { AuthContext } from "../context/AuthContext";
import CommentBox from "./CommentBox";
import useVote from "../hooks/useVote";
import PositionsTable from "./PositionsTable";
import Utils from "../utils/Utils";
import { useNavigate } from "react-router-dom";

const PostInPostPage = ({ post, updateComments }) => {
	const { theme } = useTheme();
	const [comment, setComment] = useState("");
	const [isCommenting, setIsCommenting] = useState(false);
	const { isLoggedIn, user } = useContext(AuthContext);
	const [comments, setComments] = useState([]);
	const [commentCount, setCommentCount] = useState(post.commentCount);
	const navigate = useNavigate();

	const { handleVote, upvotes, downvotes } = useVote(
		post,
		(postId, upvotes, downvotes) => {
			post.upvotes = upvotes;
			post.downvotes = downvotes;
		}
	);

	// useEffect(() => {
	// 	// This effect will run whenever the user context changes
	// 	if (user) {
	// 		// Perform any actions needed when the user updates
	// 		console.log("User updated:", user);
	// 	}
	// }, [user]);

	const handleClick = (type) => {
		if (type === "comment") {
			if (isLoggedIn) {
				setIsCommenting(true);
			} else {
				alert("You must be logged in to comment.");
			}
		}
	};

	const handleCommentSubmit = async (parentCommentId = 0) => {
		if (!isLoggedIn) {
			alert("You must be logged in to comment.");
			return;
		}

		const commentData = {
			userId: user.id,
			postId: post.postId,
			content: comment,
			parentCommentId: null,
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/comments`,
				{
					method: "POST",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(commentData),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				const newComment = {
					commentId: responseData.commentId,
					userName: user.username,
					content: comment,
					upvotes: 0,
					downvotes: 0,
					createAt: responseData.createAt,
				};

				let updatedComments;
				if (parentCommentId === 0) {
					setComments([...comments, newComment]);
				} else {
					updatedComments = comments.map((comment) => {
						if (comment.commentId === parentCommentId) {
							return {
								...comment,
								childComments: comment.childComments
									? [...comment.childComments, newComment]
									: [newComment],
							};
						}
						return comment;
					});
					setComments(updatedComments);
				}
				updateComments(updatedComments);
				setCommentCount(responseData.commentCount);
				setIsCommenting(false);
				setComment("");
			} else {
				console.error("Failed to post comment");
			}
		} catch (error) {
			console.error("Error posting comment:", error);
		}
	};

	const handleCancel = () => {
		setIsCommenting(false);
		setComment("");
	};

	const getTagColor = (tag) => {
		if (tag.startsWith("c/")) {
			return theme.palette.tags.community;
		} else if (tag.startsWith("Crypto:")) {
			return theme.palette.tags.crypto;
		} else if (tag.startsWith("index/")) {
			return theme.palette.tags.index;
		} else {
			return theme.palette.tags.stock;
		}
	};

	const formattedTime = Utils.getFormattedTime(post.createdAt);

	return (
		<div>
			<div className="post-info">
				<div className="community">{post.community}</div>
				<span className="time-user">{formattedTime} ago by &nbsp;</span>
				<span
					className="username"
					onClick={() => {
						navigate(`/profile/${post.userName}`);
					}}>
					{post.userName}
				</span>
				<span>
					<TagsList tags={post.tags} marginTop={0} />
				</span>
				<h2>{post.title}</h2>
			</div>
			{post.images.length > 0 && (
				<Carousel swipe={true} height="18rem" autoPlay={false}>
					{post.images.map((image) => (
						<Card key={image.imageId} sx={{ backgroundColor: "#293b4e" }}>
							<CardMedia
								component="img"
								sx={{ height: "18rem", objectFit: "contain" }}
								image={`${process.env.REACT_APP_SC_API_BASE_URL}/api/files/download/${image.url}`}
								alt={`image-${image.imageId}`}
							/>
						</Card>
					))}
				</Carousel>
			)}

			<div className="post-info">
				<p className="content" style={{ fontSize: "16px" }}>
					<div style={{ whiteSpace: "pre-wrap" }}>{post.content}</div>
				</p>
			</div>
			<Box>
				<PositionsTable />
			</Box>
				<Box sx={{ width: '100%' }}>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						marginTop={".5rem"}
						spacing={"1rem"}>
						<Grid item>
							<Chip
								icon={<FaArrowUp className="upvotes-arrow" />}
								label={Utils.abbreviateNumber(upvotes)}
								color="success"
								onClick={handleVote(post, "UPVOTE")}
								variant="outlined"
							/>
						</Grid>
						<Grid item>
							<Chip
								icon={<FaArrowDown className="downvotes-arrow" />}
								label={Utils.abbreviateNumber(downvotes)}
								color="error"
								onClick={handleVote(post, "DOWNVOTE")}
								variant="outlined"
							/>
						</Grid>
						<Grid item>
							<Chip
								icon={<FaComment className="interaction-icon" />}
								label={Utils.abbreviateNumber(post.commentCount)}
								color="primary"
								onClick={() => handleClick("comment")}
								variant="outlined"
							/>
						</Grid>
						<Grid item>
							<Chip
								icon={<FaShare className="share" />}
								label="Share"
								color="secondary"
								onClick={handleClick}
								variant="outlined"
							/>
						</Grid>
						<Grid item>
							<Chip
								icon={<LiaMoneyBillWaveSolid size={"1.69rem"} />}
								label="Tip"
								onClick={handleClick}
								color="success"
							/>
						</Grid>
					</Grid>
				</Box>

			{isCommenting ? (
				<CommentBox
					comment={comment}
					setComment={setComment}
					handleSubmit={() => handleCommentSubmit(0)}
					handleCancel={handleCancel}
				/>
			) : (
				<div
					className="small-comment-box"
					onClick={() => handleClick("comment")}>
					<input
						type="text"
						placeholder="Add Comment..."
						readOnly
						className="small-comment-input"
					/>
				</div>
			)}
		</div>
	);
};

export default PostInPostPage;

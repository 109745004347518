import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {PositionsSummary} from "../content/post_content/PositionsSummary";
import {useTheme} from "./ThemeContext";
import {Box, Card, CardContent, Grid, Typography, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

export default function PositionsTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const {theme} = useTheme();
    const isMobile = useMediaQuery("(max-width: 770px)"); // Detect screen size

    const columns = [
        {
            id: "position",
            label: "Position",
            minWidth: "1rem",
            formatColor: (value) => {
                value = value.toString();
                if (value.charAt(0) === "+" || value === "Long") {
                    return theme.palette.text.green;
                } else if (value.charAt(0) === "-" || value === "Short") {
                    return theme.palette.text.disabled;
                } else {
                    return theme.palette.text.primary;
                }
            },
        },
        {
            id: "stock",
            label: "Stock",
            minWidth: "1rem",
            formatColor: (value) => {
                value = value.toString();
                if (value.charAt(0) === "+") {
                    return theme.palette.text.green;
                } else if (value.charAt(0) === "-") {
                    return theme.palette.text.disabled;
                } else {
                    return theme.palette.text.primary;
                }
            },
        },
        {
            id: "postDate",
            label: "Date Aquired",
            minWidth: "1rem",
            align: "center",
            format: (value) => value.toDate("en-US"),
            formatColor: (value) => {
                value = value.toString();
                if (value.charAt(0) === "+") {
                    return theme.palette.text.green;
                } else if (value.charAt(0) === "-") {
                    return theme.palette.text.disabled;
                } else {
                    return theme.palette.text.primary;
                }
            },
        },
        {
            id: "priceAtPost",
            label: "Acquired",
            minWidth: "1rem",
            align: "center",
            format: (value) => "$" + value.toFixed(2),
            formatColor: (value) => {
                value = value.toString();
                if (value.charAt(0) === "+") {
                    return theme.palette.text.green;
                } else if (value.charAt(0) === "-") {
                    return theme.palette.text.disabled;
                } else {
                    return theme.palette.text.primary;
                }
            },
        },
        {
            id: "currentPrice",
            label: "Current",
            minWidth: "1rem",
            align: "center",
            format: (value) => "$" + value.toFixed(2),
            formatColor: (value) => {
                value = value.toString();
                if (value.charAt(0) === "+") {
                    return theme.palette.text.green;
                } else if (value.charAt(0) === "-") {
                    return theme.palette.text.disabled;
                } else {
                    return theme.palette.text.primary;
                }
            },
        },
        {
            id: "Gain",
            label: "Gain",
            minWidth: "1rem",
            align: "center",
            format: (value) => value.toFixed(2),
            formatColor: (value) => {
                value = value.toString();
                if (value.charAt(0) === "+") {
                    return theme.palette.text.green;
                } else if (value.charAt(0) === "-") {
                    return theme.palette.text.disabled;
                } else {
                    return theme.palette.text.primary;
                }
            },
        },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filteredData = PositionsSummary.slice(0, 2);

    function renderPositionTableDesktop() {
        return <TableContainer sx={{maxHeight: "10rem"}}>
            <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                    <TableRow sx={{height: "0.1rem"}}>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{
                                    margin: "0rem",
                                    minWidth: column.minWidth,
                                    backgroundColor: theme.palette.background.gray,
                                    fontWeight: "bold",
                                    color: theme.palette.text.green,
                                }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((row) => {
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                sx={{height: "0.1rem"}}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{color: column.formatColor(value)}}>
                                            {column.format && typeof value === "number"
                                                ? column.format(value)
                                                : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>;
    }

    // Mobile Card View Renderer
    function renderPositionsTableMobile() {
  return filteredData.map(
    (row, index) => (
      <Box key={index} sx={{ width: '100%', overflow: 'hidden', backgroundColor: theme.palette.background.default }}>
        <Card
          sx={{
            marginBottom: "0.5rem",
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <CardContent sx={{ padding: "0.5rem" }}>
            <Grid container spacing={1} direction="row" justifyContent="space-between" marginBottom={"-1rem"}>
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.text.green,
                    marginBottom: "0.25rem"
                  }}
                >
                  {row.stock}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    marginBottom: "0.25rem"
                  }}
                >
                  {row.position}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    marginBottom: "0.25rem"
                  }}
                >
                  {row.postDate}: ${row.priceAtPost}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    marginBottom: "0.25rem"
                  }}
                >
                  Current Price: ${row.currentPrice}
                </Typography>
              </Grid>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                  sx={{
                    backgroundColor: row.Gain.charAt(0) === "+" ? theme.palette.text.green : theme.palette.text.disabled,
                    color: theme.palette.text.dark,
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                  label={row.Gain}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    )
  );
}

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "1rem",
                borderColor: theme.palette.primary.main,
                borderWidth: "1px",
            }}>
            {isMobile ? renderPositionsTableMobile() : renderPositionTableDesktop()}
        </Paper>
    );
}

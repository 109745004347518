import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useRef,
} from "react";
import {
	Box,
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "../components/ThemeContext";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CommunityLeftSidebar from "../components/CommunityLeftSidebar";
import PostCommunity from "../components/PostCommunity";
import ResponsiveTopBar from "../components/ResponsiveTopBar";
import { ThemeProvider } from "@mui/system";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import BottomNavBarRouter from "../components/BottomNavBarRouter";
import axios from "axios";
import { PageContext } from "../context/PageContext";
import { throttle } from "lodash";

const CommunityPage = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [bottomNavValue, setBottomNavValue] = useState(1);
	const isMobile = width <= 770;
	const { theme } = useTheme();
	const [value, setValue] = useState("1");
	const { communityName } = useParams();
	const [communityInfo, setCommunityInfo] = useState(null);
	const [posts, setPosts] = useState([]);
	const { currentPage } = useContext(PageContext);

	const [page, setPage] = useState(0); // Start from page 0
	const [hasMore, setHasMore] = useState(true);
	const loadingRef = useRef(false); // Use ref for synchronous loading state

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchCommunityInfo = useCallback(async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/info?communityName=${communityName}`
			);
			setCommunityInfo(response.data);
		} catch (error) {
			console.error("Error fetching community info:", error);
		}
	}, [communityName]);

	// Fetch posts by community
	const fetchPostsByCommunity = useCallback(async () => {
		if (loadingRef.current || !hasMore) return; // Check loadingRef.current
		loadingRef.current = true; // Set loadingRef.current to true immediately
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/posts/byCommunityOrTags`,
				{
					params: {
						communityName: communityName,
						tags: communityName,
						sortBy: currentPage,
						page: page,
						size: { isMobile } ? 5 : 7,
					},
				}
			);
			const data = response.data;
			if (data && data.content) {
				setPosts((prevPosts) => {
					const newPosts = data.content.filter(
						(post) => !prevPosts.some((p) => p.postId === post.postId)
					);
					return [...prevPosts, ...newPosts];
				});
				setHasMore(!data.last); // Update hasMore based on API response
			} else {
				console.error("Unexpected response structure:", data);
			}
		} catch (error) {
			console.error("Error fetching posts by community:", error);
		} finally {
			loadingRef.current = false; // Reset loadingRef.current after request
		}
	}, [communityName, currentPage, page, hasMore, isMobile]);

	// Fetch community info on component mount and when communityName changes
	useEffect(() => {
		fetchCommunityInfo();
		setPosts([]); // Reset posts when communityName changes
		setPage(0); // Reset page when communityName changes
		setHasMore(true); // Reset hasMore when communityName changes
	}, [fetchCommunityInfo, communityName]);

	// Fetch posts whenever `page` or `communityName` changes
	useEffect(() => {
		fetchPostsByCommunity();
	}, [page, fetchPostsByCommunity, communityName]);

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Scroll handler for infinite scrolling
	useEffect(() => {
		const handleScroll = throttle(() => {
			const scrollPosition =
				window.innerHeight + document.documentElement.scrollTop;
			const threshold = document.documentElement.offsetHeight * 0.9;
			if (scrollPosition >= threshold && !loadingRef.current && hasMore) {
				setPage((prevPage) => prevPage + 1);
			}
		}, 300);

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [hasMore]);

	function communityInfoSection() {
		if (!communityInfo) return null;

		const bannerUrl = `${process.env.REACT_APP_SC_API_BASE_URL}/api/files/download/${communityInfo.banner}`;
		const iconUrl = `${process.env.REACT_APP_SC_API_BASE_URL}/api/files/download/${communityInfo.icon}`;

		return (
			<Box
				sx={{
					bgcolor: "background.paper",
					boxShadow: 1,
					borderRadius: 1,
					p: 2,
					minWidth: 300,
				}}>
				<CardMedia sx={{ height: 140 }} image={bannerUrl} title="banner" />
				<Grid
					container
					direction="row"
					alignItems="center"
					sx={{ marginTop: "-1rem" }}>
					<Grid item>
						<Avatar
							alt="Community Icon"
							src={iconUrl}
							sx={{ width: 69, height: 69 }}
						/>
					</Grid>
					<Grid item>
						<Box
							sx={{
								color: "text.secondary",
								fontSize: 24,
								fontWeight: "medium",
								marginLeft: "1rem",
							}}>
							{communityInfo.name}
						</Box>
					</Grid>
				</Grid>
				<Box
					sx={{
						color: "text.primary",
						fontSize: 14,
						fontWeight: "medium",
						marginTop: "1rem",
					}}>
					{communityInfo.description}
				</Box>
				{/* ...rest of the communityInfoSection... */}
			</Box>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					pb: isMobile ? 2 : 2,
					pt: isMobile ? 0 : 0,
					mt: 0,
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}>
				<ResponsiveTopBar isMobile={isMobile} />
				{isMobile ? (
					<Box>
						<Box sx={{ width: "100%", typography: "body1" }}>
							<TabContext value={value}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: "divider",
										marginLeft: "1rem",
									}}>
									<TabList
										onChange={handleChange}
										aria-label="lab API tabs example">
										<Tab
											label="Posts"
											value="1"
											sx={{
												fontSize: "0.75rem",
												padding: "6px 12px",
												minWidth: 50,
												color: theme.palette.secondary.main,
												"&.Mui-selected": {
													color: theme.palette.primary.main,
												},
											}}
										/>
										<Tab
											label="Trending here"
											value="2"
											sx={{
												fontSize: "0.75rem",
												padding: "6px 12px",
												minWidth: 50,
												color: theme.palette.secondary.main,
												"&.Mui-selected": {
													color: theme.palette.primary.main,
												},
											}}
										/>
										<Tab
											label="Community Info"
											value="3"
											sx={{
												fontSize: "0.75rem",
												padding: "6px 12px",
												minWidth: 50,
												color: theme.palette.secondary.main,
												"&.Mui-selected": {
													color: theme.palette.primary.main,
												},
											}}
										/>
									</TabList>
								</Box>
								<TabPanel value="1">
									{posts.map((post) => (
										<PostCommunity key={post.postId} post={post} />
									))}
								</TabPanel>
								<TabPanel value="2">
									<CommunityLeftSidebar communityName={communityName} />
								</TabPanel>
								<TabPanel value="3">{communityInfoSection()}</TabPanel>
							</TabContext>
						</Box>
					</Box>
				) : (
					<Grid
						container
						spacing={1}
						columns={10}
						justifyContent="space-evenly"
						alignItems="flex-start">
						<Grid item xs={2}>
							<CommunityLeftSidebar communityName={communityName} />
						</Grid>
						<Grid item xs={4}>
							{posts.map((post) => (
								<PostCommunity key={post.postId} post={post} />
							))}
						</Grid>
						<Grid item xs={3}>
							{communityInfoSection()}
						</Grid>
					</Grid>
				)}
				{isMobile && <BottomNavBarRouter bottomNavValue={bottomNavValue} />}
			</Box>
		</ThemeProvider>
	);
};

export default CommunityPage;

// ResponsiveTopBar.js
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	InputBase,
	Button,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Badge,
	Avatar,
	Box,
	Tooltip,
	Menu,
	MenuItem,
	Divider,
	Tab,
	Tabs,
} from "@mui/material";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { alpha, styled } from "@mui/material/styles";
import logo from "../content/images/StonksCentral_logo_no_background.png";
import { useTheme } from "./ThemeContext";
import { AuthContext } from "../context/AuthContext";
import { PageContext } from "../context/PageContext";
import Cookies from "js-cookie";
import CommunitySearch from "./CommunitySearch"; // Import the refactored component

const useScrollDirection = () => {
	const [scrollDir, setScrollDir] = useState("up");

	useEffect(() => {
		let lastScrollY = window.scrollY;

		const updateScrollDir = () => {
			const scrollY = window.scrollY;
			setScrollDir(scrollY > lastScrollY ? "down" : "up");
			lastScrollY = scrollY > 0 ? 0 : scrollY;
		};

		window.addEventListener("scroll", updateScrollDir);

		return () => {
			window.removeEventListener("scroll", updateScrollDir);
		};
	}, []);

	return scrollDir;
};

const pages = ["Hot", "Trending", "New"];

const ResponsiveTopBar = ({ isMobile }) => {
	const { theme } = useTheme();
	const { isLoggedIn, setIsLoggedIn, user, signOut } = useContext(AuthContext);
	const { currentPage, setCurrentPage } = useContext(PageContext);
	const [value, setValue] = useState(currentPage || "Hot");
	const navigate = useNavigate();
	const [communityName, setCommunityName] = useState("");

	useEffect(() => {
		if (!currentPage) {
			setCurrentPage("Hot");
		}
	}, [currentPage, setCurrentPage]);

	const handleSearchSelect = (name) => {
		setCommunityName(name);
		navigate(`/community/${name}`);
	};

	const handleLogout = () => {
		Cookies.remove("accessToken");
		Cookies.remove("user");
		setIsLoggedIn(false);
		navigate("/signin");
	};

	const scrollDirection = useScrollDirection();
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [anchorElCreate, setAnchorElCreate] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
		handleCloseNavMenu();
	};

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
		setCurrentPage(newValue);
	};

	const handleOpenCreateMenu = (event) => {
		setAnchorElCreate(event.currentTarget);
	};

	const handleCloseCreateMenu = () => {
		setAnchorElCreate(null);
	};

	const appBarStyle = {
		background: theme.palette.background.paper,
		top: scrollDirection === "down" ? "-64px" : "0px",
		visibility: scrollDirection === "up" ? "visible" : "hidden", // Hide or show the AppBar
		transition: "all 0.5s",
		transform:
			scrollDirection === "down" ? "translateY(-100%)" : "translateY(0%)",
	};
	const barSpacing = {
		transition: "all 0.5s",
		padding: scrollDirection === "up" ? 40 : 0,
	};

	const avatarUrl = user
		? `${process.env.REACT_APP_SC_API_BASE_URL}/api/files/download/${user.avatarUrl}`
		: null;

	const fetchNotifications = useCallback(() => {
		if (!isLoggedIn || !user) {
			return;
		}

		fetch(`${process.env.REACT_APP_SC_API_BASE_URL}/api/notifications`, {
			method: "GET",
			headers: {
				accept: "*/*",
				Authorization: `Bearer ${user.token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 401) {
					signOut();
					throw new Error("Unauthorized");
				}
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((notifications) => {
				setNotifications(notifications);
			})
			.catch((error) => {
				console.error("Error fetching notifications:", error);
			});
	}, [isLoggedIn, user]);

	useEffect(() => {
		fetchNotifications();
		const interval = setInterval(fetchNotifications, 15 * 60 * 1000); // Refresh every 15 minutes
		return () => clearInterval(interval);
	}, [fetchNotifications]);

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setDrawerOpen(open);
	};

	const truncateText = (text, maxLength) => {
		if (text.length <= maxLength) return text;
		const truncated = text.slice(0, maxLength);
		return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
	};

	const markAllAsRead = async () => {
		const unreadNotificationIds = notifications
			.filter((notification) => !notification.read)
			.map((notification) => notification.id);

		if (unreadNotificationIds.length === 0) return;

		try {
			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/notifications/read`,
				{
					method: "PUT",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(unreadNotificationIds),
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			// Update the notification state to mark all as read
			setNotifications((prevNotifications) =>
				prevNotifications.map((notif) => ({ ...notif, read: true }))
			);
		} catch (error) {
			console.error("Error marking all notifications as read:", error);
		}
	};

	const handleNotificationClick = async (notification) => {
		if (notification.postId && notification.communityName) {
			navigate(
				`/community/${notification.communityName}/${notification.postId}`
			);
		} else if (notification.postId && !notification.communityName) {
			navigate(`/post/${notification.postId}`);
		} else {
			console.log("Notification does not have a postId or communityName");
		}

		// Mark notification as read
		try {
			const response = await fetch(
				`${process.env.REACT_APP_SC_API_BASE_URL}/api/notifications/read`,
				{
					method: "PUT",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${user.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify([notification.id]),
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			// Update the notification state to mark it as read
			setNotifications((prevNotifications) =>
				prevNotifications.map((notif) =>
					notif.id === notification.id ? { ...notif, read: true } : notif
				)
			);
		} catch (error) {
			console.error("Error marking notification as read:", error);
		}
	};

	const drawerContent = (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100vh", // Full screen height
				width: "24rem", // Larger drawer width
			}}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}>
			{/* Fixed Notifications Title */}
			<Box
				sx={{
					padding: "1rem",
					borderBottom: `1px solid ${theme.palette.divider}`,
					position: "sticky",
					top: 0, // Fix the title at the top
					zIndex: 1, // Ensure it is above the scrollable notifications
					backgroundColor: theme.palette.background.paper, // Ensure it stays visible
				}}>
				<Typography
					variant="h6"
					component="div"
					sx={{ color: theme.palette.text.green, textAlign: "center" }}>
					Notifications
				</Typography>
			</Box>

			{/* Scrollable Notifications Section */}
			<Box
				sx={{
					flexGrow: 1, // Allow it to take the remaining space
					overflowY: "auto", // Scrollable notifications list
					padding: "1rem",
				}}>
				<Button
					variant="outlined"
					color="primary"
					fullWidth
					onClick={markAllAsRead}
					sx={{
						marginTop: "0.5rem",
						color: theme.palette.text.green,
						borderColor: theme.palette.primary.main,
						textTransform: "none",
						"&:hover": {
							backgroundColor: theme.palette.background.darkGreen,
							color: theme.palette.text.primary,
						},
					}}>
					Mark All as Read
				</Button>
				<List>
					{notifications.map((notification, index) => {
						const { postTitle, commentText, count, notificationType, read } =
							notification;

						const truncatedPostTitle = postTitle
							? truncateText(postTitle, 30)
							: null;
						const truncatedCommentText = commentText
							? truncateText(commentText, 30)
							: null;
						const typeText =
							notificationType === "reply"
								? count > 1
									? "replies"
									: "reply"
								: count > 1
								? "likes"
								: "like";

						const message = commentText
							? `Your comment "${truncatedCommentText}"`
							: `Your post "${truncatedPostTitle}"`;
						const countMessage = `has ${count} new ${typeText}`;

						return (
							<React.Fragment key={index}>
								<ListItem
									onClick={() => handleNotificationClick(notification)}
									sx={{
										width: "100%", // Full width
										margin: "0 auto", // Center the notifications
										padding: "0.5rem 1rem", // Smaller height
										cursor: "pointer",
										borderRadius: "8px",
										display: "flex", // Align the text and dot in a row
										flexDirection: "row", // Make the green dot appear on the right
										justifyContent: "space-between", // Push green dot to the right
										alignItems: "center", // Center vertically
										"&:hover": {
											backgroundColor: theme.palette.background.highlight,
										},
										transition: "background-color 0.3s ease",
									}}>
									<Box>
										<Typography
											variant="body1"
											sx={{
												fontSize: "0.8rem",
												color: theme.palette.text.primary, // Normal text color
												fontWeight: read ? "normal" : "bold",
											}}>
											{message.split('"')[0]}{" "}
											<span
												style={{
													color: theme.palette.text.green,
													fontWeight: read ? "normal" : "bold",
												}}>
												{truncatedCommentText || truncatedPostTitle}
											</span>
											{/* Highlighted variable */}
										</Typography>
										<Typography
											variant="body2"
											sx={{
												fontSize: "0.8rem",
												color: theme.palette.text.primary, // Different color for count
												marginTop: "0.25rem",
												fontWeight: read ? "normal" : "bold",
											}}>
											has{" "}
											<span style={{ color: theme.palette.text.green }}>
												{count}
											</span>{" "}
											{/* Highlighted count */}
											{` new ${typeText}`}
										</Typography>
									</Box>

									{/* Green dot for unread notifications */}
									{!read && (
										<Box
											sx={{
												width: "8px",
												height: "8px",
												backgroundColor: theme.palette.primary.main, // Green dot color
												borderRadius: "50%",
											}}
										/>
									)}
								</ListItem>

								{index < notifications.length - 1 && (
									<Divider
										variant="fullWidth"
										sx={{
											backgroundColor: theme.palette.divider,
											margin: "0.5rem 0",
										}}
									/>
								)}
							</React.Fragment>
						);
					})}
				</List>
			</Box>

			{/* Profile and Logout Buttons with Elevation */}
			<Box
				sx={{
					boxShadow: theme.shadows[3], // Add elevation for the bottom section
					padding: "1rem",
					backgroundColor: theme.palette.background.paper,
					position: "sticky",
					bottom: 0, // Keep buttons at the bottom
				}}>
				<ListItem
					onClick={() => navigate("/profile/" + user.username)}
					sx={{ marginBottom: "1rem" }}>
					<Button
						type="submit"
						fullWidth
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: theme.palette.background.paper,
							borderColor: theme.palette.primary.main,
							borderWidth: "1px",
							color: theme.palette.text.green,
							textTransform: "none",
							"&:hover": {
								backgroundColor: theme.palette.background.darkGreen,
								color: theme.palette.text.primary,
							},
						}}>
						Profile Page
					</Button>
				</ListItem>
				<ListItem onClick={handleLogout}>
					<Button
						variant="outlined"
						fullWidth
						color="secondary"
						size="medium"
						sx={{
							color: theme.palette.text.dark,
							backgroundColor: theme.palette.background.cancel,
							borderColor: theme.palette.background.cancel,
							textTransform: "none",
							"&:hover": {
								backgroundColor: theme.palette.background.cancelHighlight,
								borderColor: theme.palette.background.cancelHighlight,
								color: theme.palette.text.primary,
							},
						}}>
						Logout
					</Button>
				</ListItem>
			</Box>
		</Box>
	);

	// Filter unread notifications
	const unreadNotifications = notifications.filter(
		(notification) => !notification.read
	);

	return (
		<div style={barSpacing}>
			<AppBar position="fixed" style={appBarStyle}>
				<Container maxWidth="xl">
					<Toolbar
						disableGutters
						sx={{
							display: "flex",
							justifyContent: isMobile ? "center" : "space-between",
						}}>
						<Box
							component="img"
							src={logo}
							alt="Logo"
							sx={{
								display: { xs: "none", md: "block" },
								mr: 1,
								width: 70,
								height: 70,
							}}
						/>
						<Typography
							variant="h6"
							noWrap
							component="a"
							href="/"
							onClick={() => navigate("/")}
							sx={{
								mr: 2,
								display: { xs: "none", md: "flex" },
								fontFamily: "monospace",
								fontWeight: 700,
								letterSpacing: ".1rem",
								color: theme.palette.text.primary,
								textDecoration: "none",
							}}>
							StonksCentral
						</Typography>
						<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								sx={{
									color: theme.palette.text.green,
								}}>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", md: "none" },
								}}>
								{pages.map((page) => (
									<MenuItem key={page} onClick={() => handlePageChange(page)}>
										<Typography
											textAlign="center"
											sx={{
												color: theme.palette.text.green,
											}}>
											{page}
										</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box
							component="img"
							src={logo}
							alt="Logo"
							sx={{
								display: { xs: "flex", md: "none" },
								width: 65,
								height: 65,
								position: "absolute",
								left: "50%",
								transform: "translateX(-50%)",
							}}
						/>
						<Box
							sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mx: 4 }}>
							<Tabs
								value={value}
								onChange={handleTabChange}
								aria-label="top bar tabs">
								<Tab
									label="Hot"
									value="Hot"
									sx={{
										fontSize: "1rem",
										padding: "1rem 1 rem",
										minWidth: "3rem",
										color: theme.palette.secondary.main,
										"&.Mui-selected": {
											color: theme.palette.primary.main,
										},
									}}
								/>
								<Tab
									label="Trending"
									value="Trending"
									sx={{
										fontSize: "1rem",
										padding: "1rem  1rem",
										minWidth: "3rem",
										color: theme.palette.secondary.main,
										"&.Mui-selected": {
											color: theme.palette.primary.main,
										},
									}}
								/>
								<Tab
									label="New"
									value="New"
									sx={{
										fontSize: "1rem",
										padding: "1rem 1rem",
										minWidth: "3rem",
										color: theme.palette.secondary.main,
										"&.Mui-selected": {
											color: theme.palette.primary.main,
										},
									}}
								/>
							</Tabs>
						</Box>
						<Box
							sx={{
								alignContent: "left",
								marginRight: isMobile ? "1rem" : "0.5rem",
							}}>
							<CommunitySearch setCommunityName={handleSearchSelect} />
						</Box>

						{isLoggedIn && !isMobile && (
							<Box>
								<Tooltip title="Open create settings">
									<IconButton onClick={handleOpenCreateMenu} sx={{ p: 0 }}>
										<Button
											sx={{
												my: 2,
												backgroundColor: theme.palette.primary.main, // Set background color
												color: theme.palette.text.dark,
												display: "block",
												mx: 2,
												"&:hover": {
													backgroundColor: theme.palette.background.darkGreen, // Change background color on hover
													color: theme.palette.text.primary, // Change text color on hover
												},
											}}>
											Create
										</Button>
									</IconButton>
								</Tooltip>
								<Menu
									sx={{ mt: "45px" }}
									id="menu-create"
									anchorEl={anchorElCreate}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(anchorElCreate)}
									onClose={handleCloseCreateMenu}>
									<MenuItem
										key={"Create Post"}
										onClick={() => navigate("/createPost/")}>
										<Typography textAlign="center">Post</Typography>
									</MenuItem>
									<MenuItem
										key={"Create Community"}
										onClick={() => navigate("/createCommunity/")}>
										<Typography textAlign="center">Community</Typography>
									</MenuItem>
								</Menu>
							</Box>
						)}
						{isLoggedIn ? (
							<Box sx={{ marginLeft: "0rem" }}>
								<Tooltip title="Open settings">
									<IconButton onClick={toggleDrawer(true)} sx={{ p: 0 }}>
										<Badge
											color="error"
											badgeContent={unreadNotifications.length}
											invisible={unreadNotifications.length === 0}
											overlap="circular"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}>
											<Avatar
												alt={user.username}
												src={avatarUrl}
												sx={{ flexGrow: 1, mx: 0, my: 0 }}
											/>
										</Badge>
									</IconButton>
								</Tooltip>
								<Drawer
									anchor="right"
									open={drawerOpen}
									onClose={toggleDrawer(false)}>
									{drawerContent}
								</Drawer>
							</Box>
						) : (
							<Button
								onClick={() => navigate("/signin")}
								sx={{
									my: 2,
									backgroundColor: theme.palette.primary.main, // Set background color
									color: theme.palette.text.dark,
									display: "block",
									textTransform: "none",
									mx: 1,
									"&:hover": {
										backgroundColor: theme.palette.background.darkGreen, // Change background color on hover
										color: theme.palette.text.primary, // Change text color on hover
									},
								}}>
								Sign In
							</Button>
						)}
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
};

export default ResponsiveTopBar;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Autocomplete,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	InputBase,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useTheme } from "./ThemeContext";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.1),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.15),
	},
	marginLeft: "0rem",
	width: "auto%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(1),
		marginRight: "0rem",
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 1),
	height: "100%",
	// position: "absolute",
	// pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	// marginRight: ".5rem",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 0, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(0)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "10ch",
			"&:focus": {
				width: "30ch",
			},
		},
		[theme.breakpoints.down("sm")]: {
			width: "3ch",
		},
	},
}));

const StyledInputBaseDialog = styled(InputBase)(({ theme }) => ({
	color: theme.palette.text.primary,
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 0, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(0)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "10ch",
			"&:focus": {
				width: "30ch",
			},
		},
		[theme.breakpoints.down("sm")]: {
			width: "10ch",
		},
	},
}));

const CommunitySearch = ({ setCommunityName }) => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const [dialogOpen, setDialogOpen] = useState(false);
	const { theme } = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen size
	const [placeholder, setPlaceholder] = useState(isMobile ? "" : "Search...");
	const navigate = useNavigate(); // Initialize useNavigate

	useEffect(() => {
		if (query.length > 0) {
			const fetchData = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_SC_API_BASE_URL}/api/community/search?query=${query}`
					);
					setResults(response.data);
				} catch (err) {
					console.error("Error fetching data:", err);
				}
			};
			fetchData();
		} else {
			setResults([]);
		}
	}, [query]);

	const handleSelect = (event, value) => {
		if (value) {
			setCommunityName(value.name);
			setQuery("");
			handleBlur();
			handleDialogClose();
			navigate(`/community/${value.name}`); // Navigate to the selected community
		}
	};

	const handleDialogOpen = () => {
		if (isMobile) {
			setDialogOpen(true);
		}
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
		setQuery("");
	};
	const handleFocus = () => {
		setPlaceholder(!isMobile && "Search for Communities or Tickers...");
	};

	const handleBlur = () => {
		setPlaceholder(!isMobile && "Search...");
	};

	return (
		<Search>
			<SearchIconWrapper onClick={handleDialogOpen}>
				<SearchIcon />
				<Autocomplete
					options={results}
					getOptionLabel={(option) => option.name}
					inputValue={query}
					onInputChange={(event, newInputValue) => setQuery(newInputValue)}
					onChange={handleSelect}
					sx={{ flexGrow: 1 }} // Ensure the autocomplete takes up available space
					renderInput={(params) => (
						<StyledInputBase
							{...params.InputProps}
							placeholder={placeholder}
							onFocus={handleFocus}
							onBlur={handleBlur}
							inputProps={{
								...params.inputProps,
								"aria-label": "search",
							}}
							sx={{
								flexGrow: 1,
								display: { xs: "inline-flex", md: "flex" },
								width: isMobile ? "0.3rem" : "100%",
							}}
						/>
					)}
				/>
			</SearchIconWrapper>
			{isMobile && (
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					fullWidth
					PaperProps={{
						style: {
							margin: 0,
							top: "5rem",
							position: "absolute",
						},
					}}>
					<DialogContent
						sx={{
							display: "flex",
							alignItems: "center",
							padding: theme.spacing(2),
						}}>
						<Autocomplete
							options={results}
							getOptionLabel={(option) => option.name}
							onInputChange={(event, newInputValue) => setQuery(newInputValue)}
							onChange={handleSelect}
							sx={{ flexGrow: 1 }} // Ensure the autocomplete takes up available space
							renderInput={(params) => (
								<StyledInputBaseDialog
									{...params.InputProps}
									placeholder="Lookup Communities or Tickers"
									inputProps={{
										...params.inputProps,
										"aria-label": "search",
									}}
									sx={{
										flexGrow: 1,
										display: { xs: "inline-flex", md: "flex" },
										width: "100%",
									}}
								/>
							)}
						/>
						<IconButton
							aria-label="close"
							onClick={handleDialogClose}
							sx={{
								marginLeft: theme.spacing(2),
								color: theme.palette.secondary.main,
							}}>
							<CloseIcon />
						</IconButton>
					</DialogContent>
				</Dialog>
			)}
		</Search>
	);
};

export default CommunitySearch;
